<template>
  <div class="lead_inspection_widget">
    <ul
      class="list-group list-group-flush list my--3"
    >
      <li
        v-for="inspection in inspections"
        :key="inspection.id"
        class="list-group-item px-0 inspection-item position-relative"
        v-loading="loading"
      >
        <venue-inspection-lists
          :lead-event="leadEvent"
          :venue_name="inspection.venue_name"
          :inspection_date="inspection.inspection_date"
          :inspection_time="inspection.inspection_time"
          :inspection_status="inspection.status"
          :sale_status="inspection.sale_status"
          :sale_id="inspection.sale_id"
          :inspection_id="inspection.id"
          :venue_id="inspection.venue_id"
          :is_sale_cancellable="inspection.is_sale_cancellable"
          :cancel_reason="inspection.cancel_reason"
          :cancel_reason_details="inspection.cancel_reason_details"
          :reschedule_required="inspection.reschedule_required"
          :not_interested_in_venue="inspection.not_interested_in_venue"
          @edit="openDialogForNewInspectionDate"
          @statusChange="reloadData"
        />
      </li>
      <li
        v-for="booking in bookings"
        :key="`booking_${booking.id}`"
        class="list-group-item px-0 inspection-item position-relative"
        v-loading="loading"
      >
        <venue-inspection-lists
          :lead-event="leadEvent"
          :venue_name="booking.venue_name"
          :sale_status="booking.sale_status"
          :sale_id="booking.sale_id"
          :venue_id="booking.venue_id"
          :is_sale_cancellable="booking.is_sale_cancellable"
          @statusChange="reloadData"
        />
      </li>
      <li
        v-for="customInspection in customVenueInspections"
        :key="`custom_booking_${customInspection.custom_venue_id}`"
        class="list-group-item px-0 inspection-item position-relative"
        v-loading="loading"
      >
        <custom-venue-inspection-lists
          :lead-event="leadEvent"
          :venue_name="customInspection.venue_name"
          :inspection_date="customInspection.inspection_date"
          :inspection_time="customInspection.inspection_start_time"
          :timezone="customInspection.timezone"
          :inspection_status="customInspection.inspection_status"
          :sale_status="customInspection.sale_status"
          :sale_date="customInspection.sale_date"
          :sale_id="customInspection.sale_id"
          :custom_venue_id="customInspection.custom_venue_id"
        />
      </li>
    </ul>
    <p class="text-center mt-4" v-if="inspections.length == 0 && bookings.length == 0 && customVenueInspections.length == 0">No Venue Inspection</p>
    <el-dialog
      title="Resend New Venue Inspection Request"
      width="60%"
      v-loading="loading"
      :visible.sync="inspectionEditDialogVisible"
      v-if="inspectionEditDialogVisible"
      :append-to-body="true"
    >
      <new-inspection-form
        :lead="lead" 
        :enquiry="lastEnquiry"
        :venue_id="venue_id"
        @submitRequest="saveChangedDate"/>
    </el-dialog>
  </div>
</template>
<script>
import { DatePicker, Form, FormItem, Dialog, MessageBox } from "element-ui";
import NewInspectionFrom from "@/views/Widgets/Inspections/NewInspectionForm";
import LostWonInput from '@/views/Widgets/LostWon/LostWonInput.vue';
import VenueInspectionLists from '@/views/Widgets/Inspections/VenueInspectionLists.vue';
import CustomVenueInspectionLists from "@/views/Widgets/Inspections/CustomVenueInspectionLists.vue";
export default {
  name: "venue-inspections",
  components: {
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Dialog.name]: Dialog,
    [DatePicker.name]: DatePicker,
    [NewInspectionFrom.name]: NewInspectionFrom,
    [LostWonInput.name]: LostWonInput,
    [VenueInspectionLists.name]: VenueInspectionLists,
    [CustomVenueInspectionLists.name]: CustomVenueInspectionLists
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      inspectionEditDialogVisible: false,
      editedInspectionDate: null,
      pickerOptions: {
        disabledDate(time) {
          // Disable all dates prior today
          return time.getTime() + 24 * 60 * 60 * 1000 < Date.now();
        },
      },
      editInspectionId: null,
      formInspectionDate: {
        dateChanged: "",
      },
      rules: {
        dateChanged: [
          {
            type: "date",
            required: true,
            message: "Please pick a date",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    lead() {
      return this.$store.getters["leads/getLeadById"](this.leadEvent.lead_id);
    },
    lastEnquiry() {
      let enquiries = this.$store.getters["leadEvent/getEnquiriesByLeadEventId"](this.leadEvent.id);
      return enquiries ? (enquiries[enquiries.length - 1]) : {};
    },
    inspections() {
      return this.$store.getters["inspections/getInspectionsByLeadEventId"](
        this.leadEvent.id
      );
    },
    bookings() {
      return this.$store.getters["inspections/getBookingsByLeadEventId"](
        this.leadEvent.id
      );
    },
    customVenueInspections() {
      return this.$store.getters["inspections/getCustomVenueInspectionsByLeadEventId"](
        this.leadEvent.id
      );
    },
    venue_id() {
      if (!this.editInspectionId) {
        return null;
      }
      let inspection = this.$store.getters["inspections/getInspectionsById"](
        this.leadEvent.id, this.editInspectionId
      );
      return  inspection.venue_id;
    },
  },         
  async created() {
    await this.$store.dispatch("inspections/getInspections", this.leadEvent.id);
    await this.$store.dispatch("inspections/getSalesWithoutInspections", this.leadEvent.id);
    await this.$store.dispatch("inspections/getCustomVenueInspections", this.leadEvent.id);
    this.loading = false;
  },
  methods: {
    openDialogForNewInspectionDate(inspectionId) {
      this.editInspectionId = inspectionId;
      this.inspectionEditDialogVisible = true;
    },
    async saveChangedDate(formData) {
      this.loading = true;
      this.$store
        .dispatch("inspections/editInspectionRequest", {
          leadEventId: this.leadEvent.id,
          formData: formData,
        })
        .then(async () => {
          this.inspectionEditDialogVisible = false;
          await this.$store.dispatch("inspections/getInspections", this.leadEvent.id);
          this.loading = false;
        });
    },
    async reloadData() {
      this.loading = true;
      await this.$store.dispatch(
        "inspections/getInspections",
        this.leadEvent.id
      );
      await this.$store.dispatch(
        "inspections/getSalesWithoutInspections",
        this.leadEvent.id
      );
      await this.$store.dispatch(
        "inspections/getCustomVenueInspections",
        this.leadEvent.id
      );
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" >

.inspection-item{
  height: 100px;
}
.inspection-item .edit,
.inspection-item .cancel  {
  float: right;
  display: none;
}
.inspection-item:hover .edit,
.inspection-item:hover .cancel{
  display: block;
}
.lead_inspection_widget .controls{
  right: 0;
  top: 40%;
}
.lead_inspection_widget .controls button {
  margin-right: 5px;;
}


</style>
